import { render, staticRenderFns } from "./Experience.vue?vue&type=template&id=35a8d414&scoped=true&"
var script = {}
import style0 from "./Experience.vue?vue&type=style&index=0&id=35a8d414&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a8d414",
  null
  
)

export default component.exports